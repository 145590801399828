// UniversityCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import './card.css'
const UniversityCard = (university) => {
  return (
    <div className="university-card">
      <Link 
        to={{
          pathname: `/university/${university.university._id}`,
        }}
        className='card-link'>
        <h3>{university.university.name}</h3>
      </Link>
    </div>
  );
};

export default UniversityCard;

// AddFileScreen.js
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./style.css";
import BaseUrl from "../conf";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import GroupOfFile from "./GroupOfFile";

const AddCourseScreen = () => {
  const { universityId } = useParams();
  const [selectedFoldersList, setSelectedFoldersList] = useState({});
  const [loading, setLoading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(0);
  const [failedUploaded, setFailedUploaded] = useState([]);
  const [year, setyear] = useState(0);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const onChange = (e) => {
    setSelectedFoldersList(e);
  };

  const handleNumberChange = (e) => {
    const input = e.target.value.replace(/[^0-9]/g, "");
    setyear(input);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let count = 0;
    for (const key in selectedFoldersList) {
      if (selectedFoldersList.hasOwnProperty(key)) {
        const value = selectedFoldersList[key];
        for (const file of value) {
          try {
            const formData = new FormData();
            formData.append("courseName", key);
            formData.append("realsYear", "");
            formData.append("universityYear", year);
            formData.append("universityId", universityId);
            formData.append("data", file);
            const response = await fetch(`${BaseUrl}/file/`, {
              method: "POST",
              "Content-type": "multipart/form-data",
              headers: { Authorization: `Bearer ${token}` },
              body: formData,
            });
            if (!response.ok) {
              toast(`HTTP error! Status: ${response.status}`);
              failedUploaded.push({ key, name: file.name });
              setFailedUploaded(failedUploaded);
            } else {
              count++;
              setFileUploaded(count);
            }
          } catch (error) {
            failedUploaded.push({ key, name: file.name });
            setFailedUploaded(failedUploaded);
          }
        }
      }
    }
    setLoading(false);
    // navigate(`/university/${universityId}`);
  };
  return (
    <div>
      <label htmlFor="numberInput">Enter Number:</label>
      <input
        type="number"
        id="numberInput"
        value={year}
        onChange={handleNumberChange}
      />
      {loading && <p>Loading... </p> && <h4>{fileUploaded}</h4>}
     

      {failedUploaded.length > 0 && (
          <p>failedUploaded Count {failedUploaded.length}</p>
        ) && (
          <div class="list-container">
            <div class="list-item">
              {failedUploaded.map((e) => (
                <div>
                  <span>{e.name}</span>
                  <input type="checkbox" class="checkbox" />
                </div>
              ))}
            </div>
          </div>
        )}
      <button onClick={handleSubmit}>Submit</button>
      <GroupOfFile onChange={onChange} />
    </div>
  );
};

export default AddCourseScreen;

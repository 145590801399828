// UniversityPage.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./add.css"; // Import the CSS file for UniversityPage styles
import BaseUrl from "../conf";

const UniversityPage = () => {
  const [name, setName] = useState("");
  const [maxYear, setMaxYear] = useState("");
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([{ courseName: "", courseYear: "" }]);

  const navigate = useNavigate();

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleCourseChange = (index, key, value) => {
    const updatedCourses = [...courses];
    updatedCourses[index][key] = value;
    setCourses(updatedCourses);
  };

  const addCourseRow = () => {
    setCourses([...courses, { courseName: "", courseYear: "" }]);
  };

  const removeCourseRow = (index) => {
    const updatedCourses = [...courses];
    updatedCourses.splice(index, 1);
    setCourses(updatedCourses);
  };

  const handleMaxYearChange = (event) => {
    setMaxYear(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Show loader
    setLoading(true);
        // Simulate an asynchronous API call (replace with your actual API request)
    try {
      // Add your API request logic here
      // For demonstration purposes, use a timeout to simulate an API call
      const token = localStorage.getItem('token');

      const response = await fetch(`${BaseUrl}/university/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          max_year: maxYear,
          courses: courses.map((e) => {
            return { course_name: e.courseName, course_year: e.courseYear };
          }),
        }),
      });

      if (!response.ok) {
        throw new Error("API request failed");
      }

      // Assuming the API call was successful, navigate back
      navigate("/home");
    } catch (error) {
      // Handle API error (you can add error handling logic here)
      console.error("API Error:", error);
    } finally {
      // Hide loader
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Add University Page</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">University Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={handleNameChange}
          />
        </div>
        <div>
          <label htmlFor="maxYear">Max Year:</label>
          <input
            type="number"
            id="maxYear"
            value={maxYear}
            onChange={handleMaxYearChange}
          />
        </div>

        <div>
          <h2>Courses</h2>
          <table>
            <thead>
              <tr>
                <th>Course Name</th>
                <th>Course Year</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {courses.map((course, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      value={course.courseName}
                      onChange={(e) =>
                        handleCourseChange(index, "courseName", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={course.courseYear}
                      onChange={(e) =>
                        handleCourseChange(index, "courseYear", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      onClick={() => removeCourseRow(index)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <button type="button" onClick={addCourseRow}>
            Add Course
          </button>
        </div>

        <div>
          <button type="submit" disabled={loading}>
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UniversityPage;

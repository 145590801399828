import "./App.css";
import { Routes, Route } from "react-router-dom";
import UniversityList from "./university-list/universityList";
import LogIn from "./login/logIn";
import AddUniversity from "./university-list/addUniversity";
import UniversityDetails from "./university-details/universityDetail";
import AddCourseScreen from "./add-course/AddFiles";
import AddFileScreen from "./add-file/AddFile";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<LogIn />} />
        <Route path="/home" exact element={<UniversityList />} />
        <Route path="/university/add" element={<AddUniversity />} />
        <Route path="/university/:id" element={<UniversityDetails />} />
        <Route path="/add-file/:universityId/:courseName" element={<AddFileScreen/>} />
        <Route path="/add-course/:universityId/" element={<AddCourseScreen/>} />

      </Routes>
    </>
  );
}

export default App;

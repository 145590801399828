import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./style.css";
import UniversityCard from './universityCard';
import BaseUrl from './../conf.js';


const UniversityList = () => {
  const [universities, setUniversities] = useState([]);

  useEffect(() => {
    const fetchUniversities = async () => {
      try {
      const token = localStorage.getItem('token');
        const response = await fetch(`${BaseUrl}/university/`,
        {headers: {
          Authorization: `Bearer ${token}`,
        }});
        if (!response.ok) {
          throw new Error('Failed to fetch universities');
        }

        const data = await response.json();
        setUniversities(data);
      } catch (error) {
        console.error('API Error:', error);
      }
    };

    fetchUniversities();
  }, []); // Empty dependency array ensures the effect runs once when the component mounts

  return (
    <div className="university-list-container">
      <h1 className="list-heading">University List</h1>
      <div className="university-list">
        {universities.map((university) => (
          <UniversityCard  university={university}/>
        ))}
      </div>
      <Link to="/university/add" className="add-button">
        +
      </Link>
    </div>
  );
};
  
  export default UniversityList;
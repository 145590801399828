// UniversityDetails.js
import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import "./style.css"; // Import the CSS file for UniversityDetails styles
import BaseUrl from "../conf";

const UniversityDetails = () => {
  const { id } = useParams();
  const [selectedOption, setSelectedOption] = useState("");
  const [years, setYears] = useState([]);
  const [name, setName] = useState("");
  const [university, setUniversity] = useState({});
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        const token = localStorage.getItem("token");

        const response = await fetch(`${BaseUrl}/university/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch universities");
        }
        const data = await response.json();
        setName(data.name);
        setUniversity(data);
        const dropdownOptions = Array.from(
          { length: data.max_year },
          (_, index) => index
        );
        setYears(dropdownOptions);
      } catch (error) {
        console.error("API Error:", error);
      }
    };

    fetchUniversities();
  }, []);

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setTableData(
      university.courses.filter((e) => e.course_year === selectedValue)
    );

    // Dummy data for the table based on the selected option
    // Replace this with your actual data fetching logic
  };

  const handleAddButtonClick = (courseName) => {

    // Navigate to the AddFileScreen with the course ID
    navigate(`/add-file/${university._id}/${courseName}`);
  };

  return (
    <div className="details-container">
      <h1 className="details-heading">University Details - {name}</h1>

      <div className="dropdown-container">
        <label htmlFor="options">Select an Option:</label>
        <select
          id="options"
          value={selectedOption}
          onChange={handleDropdownChange}
        >
          <option value="">-- Select --</option>
          {years.map((option, index) => (
            <option key={index} value={option + 1}>
              {option + 1}
            </option>
          ))}
        </select>
      </div>

      {selectedOption && (
        <div className="table-container">
          <h2>Course Table</h2>
          <table>
            <thead>
              <tr>
                <th>Course Name</th>
                <th>Years</th>
                <th>Files</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row) => (
                <tr key={row.id}>
                  <td>{row.course_name}</td>
                  <td>{row.course_year}</td>
                  <td>{row.files.map((e) => e.file_year)}</td>
                  <td>
                    <button
                      onClick={() => handleAddButtonClick(row.course_name)}
                    >
                      Add
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Link
        to={{ pathname: `/add-course/${university._id}` }}
        className="add-button"
      >
        +
      </Link>
    </div>
  );
};

export default UniversityDetails;

// AddFileScreen.js
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import BaseUrl from "../conf";

const AddFileScreen = () => {
  const navigate = useNavigate();
  const { courseName, universityId } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState("");

  const handleFileChange = (event) => {
    // Handle file input change
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleYearChange = (event) => {
    // Handle year input change
    const value = event.target.value;
    setYear(value);
  };

  const handleSubmit = async (event) => {

    // Handle form submission (you can add your logic here)
    event.preventDefault();
    const formData = new FormData();
    formData.append("courseName", courseName);
    formData.append("year", year);
    formData.append("universityId", universityId);
    formData.append("data", selectedFile);
    try {
      setLoading(true);
      const response = await fetch(`${BaseUrl}/file/`, {
        method: "POST",
        "Content-type": "multipart/form-data",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      if (!response) {
        toast("An error occurred. Please try again later.");
      }
    } catch (error) {
      toast("An error occurred. Please try again later.");
    }
    setLoading(false);

    setSelectedFile(null);
    setYear("");
    navigate(`/university/${universityId}`);
  };

  return (
    <div>
      <h1>Add File Screen - Course ID: {courseName}</h1>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="file">Select a File:</label>
          <input type="file" id="file" onChange={handleFileChange} />
        </div>

        <div>
          <label htmlFor="year">Year:</label>
          <input
            type="text"
            id="year"
            value={year}
            onChange={handleYearChange}
          />
        </div>

        <div>
          <button type="submit"> {loading ? "loading..." : "Submit"}</button>
        </div>
      </form>
    </div>
  );
};

export default AddFileScreen;

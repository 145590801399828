// AddFileScreen.js
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./style.css";
import BaseUrl from "../conf";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddCourseScreen = ({ onChange }) => {
  const handleFolderChange = (event) => {
    const files = event.target.files;

    const folders = {};

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const folderPath = file.webkitRelativePath.split('/')[1];
      if (!folders[folderPath]) {
        folders[folderPath] = [];
      }

      folders[folderPath].push(file);
    }

    onChange(folders)
  };

  return (
    <div>
      <input
        type="file"
        directory=""
        webkitdirectory=""
        onChange={handleFolderChange}
        multiple
      />
    </div>
  );
};

export default AddCourseScreen;
